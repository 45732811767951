import { type RouteDefinition } from '@solidjs/router';
import Post, { getFullPost } from '~/components/Post';

export const route = {
  preload: ({ location }) => {
    const [, category, year, month, slug] = location.pathname.split('/');
    return getFullPost({ category, year, month, slug });
  },
} satisfies RouteDefinition;

export default function ViewPost() {
  return <Post />;
}
